import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { Button } from "jasper-ui"

const GuideBookSectionStyles = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 96px;

  .guidebook__button {
    font-size: 14px;
    font-weight: bold;
    font-family: "DM Sans";
  }

  .guidebook__imagewrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
  }

  .guidebook__image {
    margin: 36px;
    width: 100%;
    min-width: 244px;
    max-width: 350px;
  }

  .guidebook__header {
    margin: 0;
    margin-bottom: 36px;
  }

  .guidebook__lead {
    color: #efb567;
    font-family: "itc";
    font-size: 16px;
    margin: 0;
    margin-bottom: 16px;
  }

  .guidebook__paragraph {
    font-size: 14px;
    word-spacing: 4px;
    line-height: 140%;
  }

  .guidebook__button {
    margin: 32px 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 200px;
    flex-direction: row;

    .guidebook__image {
      max-width: 400px;
    }

    .guidebook__imagewrapper {
      flex-basis: 52%;
      justify-content: flex-start;
    }

    .guidebook__lead {
      color: #ffcb15;
      font-size: 18px;
    }

    .guidebook__paragraph,
    .guidebook__button {
      font-size: 16px;
    }

    .guidebook__textwrapper {
      flex-basis: 48%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export default function GuidebookSection() {
  return (
    <GuideBookSectionStyles>
      <div className="guidebook__imagewrapper">
        <StaticQuery
          query={graphql`
            query {
              imageSharp(
                fluid: { originalName: { eq: "guidebook_hangout.png" } }
              ) {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          `}
          render={(data) => {
            return (
              <Img fluid={data.imageSharp.fluid} className="guidebook__image" />
            )
          }}
        />
      </div>
      <div className="guidebook__textwrapper">
        <h2 className="guidebook__header">
          COMPFEST 12 : <br /> Online Playground Challange
        </h2>
        <div>
          <h4 className="guidebook__lead">
            <FormattedMessage id="playground.howToPlay.guidebook.title" />
          </h4>
          <p className="guidebook__paragraph">
            <FormattedMessage id="playground.howToPlay.guidebook.description" />
          </p>
          <Button
            className="guidebook__button"
            href="http://bit.ly/GuidebookPlaygroundCF"
          >
            <FormattedMessage id="playground.howToPlay.guidebook.downloadButton" />
          </Button>
        </div>
      </div>
    </GuideBookSectionStyles>
  )
}
