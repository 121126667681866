import React from "react"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"
import Faq from "react-faq-component"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import PlaygroundPage from "../../components/PlaygroundPage"
import StickyPointsBar from "../../components/PlaygroundPage/stickyPointsBar"
// import SponsorMedpar from "../../components/SponsorMedpar"
import { useLocalization } from "gatsby-theme-i18n"

import PlaygroundData from "../../../assets/data/playground/about.json"
import GuidebookSection from "../../components/PlaygroundPage/About/Guidebook"

const PlaygroundAboutStyle = styled.div`
  .about__header {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .about__spacer {
    margin-top: 72px;
  }

  @media screen and (min-width: 768px) {
    .about__spacer {
      margin-top: 180px;
    }
  }
`

const CardSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  .cards {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .card {
    box-sizing: border-box;
    font-family: "DM Sans";
    background: #2e2e2e;
    align-items: center;
    border-radius: 10px;
    width: 273px;
    display: flex;
    flex-direction: column;
    padding: 36px;

    h4 {
      margin: 24px 0 12px 0;
    }

    p {
      font-size: 14px;
      text-align: center;
    }
  }
`

const FAQSection = styled.section`
  display: flex;
  margin-top: 96px;
  flex-direction: column;
  align-items: center;

  .faq-list {
    max-width: 850px;
    margin: auto;

    div > section > section {
      padding: 0;
      border: none;
      margin-bottom: 16px;
    }

    section > div[role="button"] {
      > div {
        font-size: 16px;
        font-weight: 500;
      }

      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      background: linear-gradient(90deg, #ec006a 0%, #f66c3d 100%);
      border-radius: 10px 10px 0px 0px;
    }

    section > div[role="button"][aria-expanded="false"] {
      border-radius: 10px;
    }

    section > div[role="region"] {
      > div {
        font-size: 14px !important;

        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
        word-spacing: 4px;
      }

      background: #f3f3f3;
      border-radius: 0px 0px 10px 10px;
    }

    section > div[role="region"][aria-expanded="false"] {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 200px;

    .faq-list {
      div > section > section {
        margin-bottom: 20px;
      }

      section > div[role="button"] {
        > div {
          font-size: 18px;
        }
        padding-left: 32px !important;
        padding-right: 32px !important;
        padding-top: 24px !important;
        padding-bottom: 24px !important;
      }

      section > div[role="region"] {
        > div {
          font-size: 16px !important;

          padding-left: 32px !important;
          padding-right: 32px !important;
          padding-top: 24px !important;
          padding-bottom: 24px !important;
        }
      }
    }
  }
`

export const ContactSection = styled.div`
  margin-top: 96px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .contact__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .contact__items {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contact__header {
      margin: 8px 0;
      background-image: linear-gradient(135deg, #ec006a 0%, #ffcb15 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }

    .contact__text {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 200px;

    .contact__wrapper {
      gap: 170px;
    }
  }
`

const faqStyles = {
  bgColor: "transparent",
  titleTextColor: "#F3F3F3",
  rowTitleColor: "#F3F3F3",
  rowContentColor: "#01081F",
  arrowColor: "#F3F3F3",
}

const faqConfig = {
  animate: true,
}

const { cards, faqID, faqEN } = PlaygroundData

const About = () => {
  const { locale } = useLocalization()

  const fetchFaq = () => {
    if (locale === "en") {
      return faqEN
    }
    return faqID
  }

  return (
    <Layout>
      <SEO title="Prizes" />
      <PlaygroundAboutStyle className="layout">
        <PlaygroundPage hideCountdown />
        <StickyPointsBar />

        <CardSection>
          <h2 className="about__header">
            <FormattedMessage id="playground.howToPlay" />
          </h2>
          <div className="cards">
            {cards.map((c, i) => (
              <div className="card" key={i}>
                <StaticQuery
                  query={graphql`
                    query {
                      allFile(
                        filter: {
                          relativePath: {
                            regex: "/images/playground-assets/about_/"
                          }
                        }
                      ) {
                        nodes {
                          childImageSharp {
                            fixed(height: 154) {
                              ...GatsbyImageSharpFixed
                              originalName
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={(data) => {
                    const image = data.allFile.nodes.find(
                      (node) =>
                        node.childImageSharp.fixed.originalName === c.image
                    )
                    if (!image) {
                      return null
                    }
                    return <Img fixed={image.childImageSharp.fixed} />
                  }}
                />
                <h4>
                  <FormattedMessage id={c.title} />
                </h4>
                <p>
                  <FormattedMessage id={c.description} />
                </p>
              </div>
            ))}
          </div>
        </CardSection>

        <GuidebookSection />

        <FAQSection>
          <h2 className="about__header">
            <FormattedMessage id="playground.howToPlay.faq" />
          </h2>
          <div className="faq-list">
            <Faq
              data={{ rows: fetchFaq() }}
              styles={faqStyles}
              config={faqConfig}
            />
          </div>
        </FAQSection>

        <ContactSection>
          <h2 className="about__header">
            <FormattedMessage id="contactPerson" />
          </h2>
          <div className="contact__wrapper">
            <div className="contact__items">
              <h2 className="contact__header">Muhammad Rifqi</h2>
              <h2 className="contact__text">082211133499</h2>
            </div>
            <div className="contact__items">
              <h2 className="contact__header">Hanifah Rahmajati</h2>
              <h2 className="contact__text">087887665730</h2>
            </div>
          </div>
        </ContactSection>

        <div className="about__spacer" />
      </PlaygroundAboutStyle>
    </Layout>
  )
}
export default About
